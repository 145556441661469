import {Box, Button} from '@mui/material';
import Topbar from '../../components/Topbar';
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
import {useMemo, useRef, useState} from 'react';
import RepsFilter from './RepsFilter';
import SalesTerritoriesModal from './SalesTerritoriesModal';
import Grid from "@mui/material/Grid";
import {useReps} from "../../hooks/useReps";
import RepModal from "./RepModal";
import {useCurrentUser} from "../../hooks/useCurrentUser";
import {Cancel, CheckCircle} from "@mui/icons-material";
import {useLocalStorage} from "../../hooks/useLocalStorage";
import {translateTextToBoolean} from "../../util/UserUtil";
import {useManagedRepTypes} from "../../hooks/useManagedRepTypes";
import PageHeader from "../../components/PageHeader";

const RepsTable = () => {

    console.log('Rendering RepsTable');

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 20,
    });

    const [filter, setFilter] = useState({
        businessUnitCode: '',
        repTypeId: '',
        activated: '',
        salesRegion: '',
        salesTerritory: '',
    });

    const [columnWidths, setColumnWidths] = useLocalStorage('columnWidths', {});

    const [currentUser, _updateCurrentUser] = useCurrentUser(true, (user) => {
        console.log("Current User");
        console.log(user);
        if (user) {
            if (user.businessUnitCode) {
                setFilter(prevState => ({
                    ...prevState,
                    businessUnitCode: user.businessUnitCode,
                }));
            }
            if (user.salesRegion) {
                setFilter(prevState => ({
                    ...prevState,
                    salesRegion: user.salesRegion,
                }));
            }
        }
    });

    const [salesTerritoriesModalData, setSalesTerritoriesModalData] = useState(
        {open: false, data: []});

    const salesTerritoriesRef = useRef();
    const repModelRef = useRef();

    const columns = useMemo(() => {
        return [
            {field: 'id', headerName: 'ID', width: columnWidths.id ?? 70},
            {
                field: 'repType',
                headerName: 'REP TYPE',
                width: columnWidths.repType ?? 250,
                renderCell: (params) => {
                    return params.value ? params.value.name : 'NA';
                }
            },
            {field: 'businessUnitCode', headerName: 'BUSINESS UNIT', width: columnWidths.businessUnitCode ?? 130},
            {field: 'login', headerName: 'LOGIN', width: columnWidths.login ?? 250},
            {field: 'firstName', headerName: 'FIRST NAME', width: columnWidths.firstName ?? 100},
            {field: 'lastName', headerName: 'LAST NAME', width: columnWidths.lastName ?? 100},
            {field: 'email', headerName: 'EMAIL', width: columnWidths.email ?? 200},
            {field: 'salesRegion', headerName: 'REGION', width: columnWidths.salesRegion ?? 80},
            {
                field: 'salesTerritories',
                headerName: 'TERRITORIES',
                width: columnWidths.salesTerritories ?? 200,
                renderCell: (params) => {
                    // console.log("Sales Territory Button")
                    // console.log(params);
                    return (params.value.length === 0 ? "" :
                            <Button onClick={(event) => {
                                event.stopPropagation();
                                setSalesTerritoriesModalData({
                                    open: true,
                                    data: params.value,
                                });
                                salesTerritoriesRef.current.setSalesTerritory(
                                    params.value.length > 0 ? params.value[0] : '');
                            }}>
                                {params.value ? params.value.join(', ') : 'NA'}
                            </Button>
                    );
                },
            },
            {
                field: 'adminPortalAccess',
                headerName: 'ADMIN PORTAL ACCESS',
                width: columnWidths.adminPortalAccess ?? 180,
                renderCell: (params) => (
                    <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                        {params.value ? <CheckCircle color="success"/> : <Cancel color="error"/>}
                    </Box>
                )
            },
            {
                field: 'lastLoginDate',
                headerName: 'LAST LOGIN DATE',
                width: columnWidths.lastLoginDate ?? 170,
                valueFormatter: (value) => {
                    return value ?
                        new Date(value).toLocaleString() :
                        'Never';
                },
            },
            {
                field: 'activated',
                headerName: 'ACTIVATED',
                width: columnWidths.activated ?? 100,
                renderCell: (params) => (
                    <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                        {params.value ? <CheckCircle color="success"/> : <Cancel color="error"/>}
                    </Box>
                )
            },
            // {
            //     field: 'lastCompletedFittingDate',
            //     headerName: 'LAST COMPLETED FITTING DATE',
            //     valueFormatter: (value) => {
            //         return value ? new Date(value).toLocaleString() : 'NA';
            //     },
            // },
        ];
    }, []);

    const [reps, totalRowCount, refetchReps] = useReps(
        filter.businessUnitCode,
        filter.repTypeId,
        filter.activated,
        filter.salesRegion,
        filter.salesTerritory,
        paginationModel.page,
        paginationModel.pageSize
    );

    const handleColumnResize = (params) => {
        setColumnWidths(prevWidths => ({
            ...prevWidths,
            [params.colDef.field]: params.width,
        }));
    };

    function handleRowClick(params) {
        console.log(params);
        repModelRef.current.handleOpen(params.row.id);
    }

    function handleCloseModal() {
        setSalesTerritoriesModalData((prev) => ({
            ...prev,
            open: false,
        }));
    }

    const filterChangeFunctions = {
        businessUnitCode: (value) => {
            setFilter(prevState => ({
                ...prevState,
                businessUnitCode: value,
                salesRegion: '',
                salesTerritory: '',
            }));
        },
        repTypeId: (value) => {
            setFilter(prevState => ({
                ...prevState,
                repTypeId: value,
            }));
        },
        activated: (value) => {
            setFilter(prevState => ({
                ...prevState,
                activated: value,
            }));
        },
        salesRegion: (value) => {
            setFilter(prevState => ({
                ...prevState,
                salesRegion: value,
                salesTerritory: '',
            }));
        },
        salesTerritory: (value) => {
            setFilter(prevState => ({
                ...prevState,
                salesTerritory: value,
            }));
        },
    }

    function renderControlPanel() {
        return (
            <Box pt={1}>
                <Grid container spacing={1}
                      sx={{
                          display: 'flex',
                          // justifyContent: 'center',
                          alignItems: 'center',
                      }}>
                    <Grid item xs={10}

                    >
                        <RepsFilter
                            filter={filter}
                            filterChangeFunctions={filterChangeFunctions}
                            setFilter={setFilter}
                        />
                    </Grid>
                    <Grid item xs={2} sx={{
                        display: 'flex',
                        justifyContent: 'right',
                        // alignItems: 'center',
                    }}>
                        <Button variant="contained" color="primary"
                                onClick={() => repModelRef.current.handleOpen()}>
                            + New Rep
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        );
    }

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                {/*<Topbar title="Manage Reps"/>*/}
                <PageHeader title="Manage Reps" children={renderControlPanel()}/>

                <Box sx={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                }}>
                    <DataGrid
                        columns={columns}
                        rows={reps}
                        paginationMode="server"
                        rowCount={totalRowCount}
                        pageSizeOptions={[10, 20, 50]}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        slots={{toolbar: GridToolbar}}
                        onRowClick={handleRowClick}
                        disableRowSelectionOnClick={true}
                        onColumnWidthChange={handleColumnResize}
                        sx={{
                            height: '100%',
                            position: 'absolute',
                            width: '100%',
                            '& .MuiDataGrid-cell:focus': {
                                outline: 'none',
                            },
                            // fontSize: '1rem',
                        }}
                    />
                </Box>
            </Box>
            <SalesTerritoriesModal ref={salesTerritoriesRef} payload={salesTerritoriesModalData}
                                   closeModal={handleCloseModal}/>
            {/*<CreateRepModal ref={createRepModelRef}/>*/}
            <RepModal ref={repModelRef} refetchReps={refetchReps}/>
        </>
    );
};

export default RepsTable;
