import {Box, Button} from '@mui/material';
import {DataGrid, GridToolbar} from '@mui/x-data-grid';
import {useMemo, useRef, useState} from 'react';
import {useLocalStorage} from "../../hooks/useLocalStorage";
import PageHeader from "../../components/PageHeader";
import MyBooleanMark from "../../components/MyBooleanMark";
import {useEvents} from "../../hooks/useEvents";
import {useEventTypes} from "../../hooks/useEventTypes";
import {useBookabilityLevels} from "../../hooks/useBookabilityLevels";
import {useSpecsVisibilities} from "../../hooks/useSpecsVisibilities";
import {toPercentage} from "../../util/NumberUtil";

const defaultColumnWidths = {
    id: 70,
    scheduledStart: 200,
    scheduledEnd: 200,
    locationName: 240,
    address: 400,
    types: 180,
    shipto: 300,
    golfPro: 400,
    onsiteClubBuilding: 200,
    shareLink: 170,
    fitterCount: 130,
    businessUnitCode: 140,
    active: 80,
    languageCode: 100,
    bookabilityLevel: 170,
    specsVisibility: 150,
    fittingDurationOpt: 200,
    fittingsCount: 150,
    bookedPercentage: 180,
    completedPercentage: 200,
}

const EventsTable = () => {

    console.log('Rendering EventsTable');

    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: 20,
    });

    const [eventColumnWidths, setEventColumnWidths] = useLocalStorage('eventColumnWidths', {});

    // const eventTypes = useEventTypes();
    // const bookabilityLevels = useBookabilityLevels();
    // const specsVisibilities = useSpecsVisibilities();

    const columns = useMemo(() => {
        return [
            {field: 'id', headerName: 'ID', width: eventColumnWidths.id ?? defaultColumnWidths.id},
            {
                field: 'scheduledStart',
                headerName: 'START TIME',
                width: eventColumnWidths.scheduledStart ?? defaultColumnWidths.scheduledStart,
                renderCell: (params) => {
                    if (params.value) {
                        return new Date(params.value).toLocaleString();
                    } else {
                        return 'Unknown';
                    }
                }
            },
            {
                field: 'scheduledEnd',
                headerName: 'END TIME',
                width: eventColumnWidths.scheduledEnd ?? defaultColumnWidths.scheduledEnd,
                renderCell: (params) => {
                    if (params.value) {
                        return new Date(params.value).toLocaleString();
                    } else {
                        return 'Unknown';
                    }
                }
            },
            {
                field: 'locationName',
                headerName: 'LOCATION',
                width: eventColumnWidths.locationName ?? defaultColumnWidths.locationName,
                renderCell: (params) => {
                    if (params.row.facility) {
                        return params.row.facility.name;
                    } else if (params.row.location) {
                        return params.row.location.name;
                    } else {
                        return 'Unknown';
                    }
                }
            },
            {
                field: 'address',
                headerName: 'ADDRESS',
                width: eventColumnWidths.address ?? defaultColumnWidths.address,
                renderCell: (params) => {
                    if (params.row.facility) {
                        let facility = params.row.facility;
                        return [facility.address1, facility.address2, facility.city, facility.state, facility.postalCode].filter(Boolean).join(', ');
                    } else if (params.row.location) {
                        let location = params.row.location;
                        return [location.address1, location.address2, location.city, location.state, location.postalCode].filter(Boolean).join(', ');
                    } else {
                        return 'Unknown';
                    }
                }
            },
            {
                field: 'types',
                headerName: 'TYPES',
                width: eventColumnWidths.types ?? defaultColumnWidths.types,
                renderCell: (params) => {
                    const {eventType, eventProductType, indoor} = params.row;
                    let eventTypeName = eventType?.name;
                    let eventProductTypeName = eventProductType?.name;
                    let indoorOutdoor = indoor === null ? null : (indoor ? 'Indoor' : 'Outdoor');
                    return [eventTypeName, eventProductTypeName, indoorOutdoor].filter(Boolean).join(', ');
                }
            },
            {
                field: 'shipto',
                headerName: 'SHIPTO',
                width: eventColumnWidths.shipto ?? defaultColumnWidths.shipto,
                renderCell: (params) => {
                    let shipto = params.row.shipto;
                    return shipto ? shipto.name + ' (' + shipto.shiptoId + ')' : 'Unknown';
                }
            },
            {
                field: 'golfPro',
                headerName: 'GOLF PRO',
                width: eventColumnWidths.golfPro ?? defaultColumnWidths.golfPro,
                renderCell: (params) => {
                    // const {eventAdmins} = params.row;
                    // let golfPro;
                    // if (eventAdmins) {
                    //     golfPro = eventAdmins.find(admin => admin.isPro);
                    // }
                    // return golfPro ? [golfPro.firstName, golfPro.lastName].filter(Boolean).join(' ') + ' (' + golfPro.email + ')' : 'None';
                    const {golfProFirstName, golfProLastName, golfProEmail} = params.row;
                    return [golfProFirstName, golfProLastName].filter(Boolean).join(' ') + ' (' + golfProEmail + ')';
                }
            },
            {
                field: 'onsiteClubBuilding',
                headerName: 'ONSITE CLUB BUILDING',
                width: eventColumnWidths.onsiteClubBuilding ?? defaultColumnWidths.onsiteClubBuilding,
                renderCell: (params) => (
                    <MyBooleanMark value={params.value}/>
                )
            },
            {field: 'shareLink', headerName: 'SHARE LINK', width: eventColumnWidths.shareLink ?? defaultColumnWidths.shareLink},
            {field: 'fitterCount', headerName: 'FITTER COUNT', width: eventColumnWidths.fitterCount ?? defaultColumnWidths.fitterCount},
            {field: 'businessUnitCode', headerName: 'BUSINESS UNIT', width: eventColumnWidths.businessUnitCode ?? defaultColumnWidths.businessUnitCode},
            {
                field: 'active',
                headerName: 'ACTIVE',
                width: eventColumnWidths.active ?? defaultColumnWidths.active,
                renderCell: (params) => (
                    <MyBooleanMark value={params.value}/>
                )
            },
            {field: 'languageCode', headerName: 'LANGUAGE', width: eventColumnWidths.languageCode ?? defaultColumnWidths.languageCode},
            {
                field: 'bookabilityLevel',
                headerName: 'BOOKABILITY LEVEL',
                width: eventColumnWidths.bookabilityLevel ?? defaultColumnWidths.bookabilityLevel,
                renderCell: (params) => {
                    return params.value ? params.value.bookabilityLevelName : 'Unknown';
                }
            },
            {
                field: 'specsVisibility',
                headerName: 'SPECS VISIBILITY',
                width: eventColumnWidths.specsVisibility ?? defaultColumnWidths.specsVisibility,
                renderCell: (params) => {
                    return params.value ? params.value.name : 'Unknown';
                }
            },
            {
                field: 'fittingDurationOpt',
                headerName: 'FITTING DURATION',
                width: eventColumnWidths.fittingDurationOpt ?? defaultColumnWidths.fittingDurationOpt,
                renderCell: (params) => {
                    return params.value ? params.value.name : 'Unknown';
                }
            },
            {field: 'fittingsCount', headerName: 'FITTINGS COUNT', width: eventColumnWidths.fittingsCount ?? defaultColumnWidths.fittingsCount},
            {
                field: 'bookedPercentage',
                headerName: 'BOOKED PERCENTAGE',
                width: eventColumnWidths.bookedPercentage ?? defaultColumnWidths.bookedPercentage,
                renderCell: (params) => {
                    return toPercentage(params.value);
                }
            },
            {
                field: 'completedPercentage',
                headerName: 'COMPLETED PERCENTAGE',
                width: eventColumnWidths.completedPercentage ?? defaultColumnWidths.completedPercentage,
                renderCell: (params) => {
                    return toPercentage(params.value);
                }
            },
        ];
    }, []);

    const [events, totalRowCount, refetchEvents] = useEvents(
        paginationModel.page,
        paginationModel.pageSize
    );

    const handleColumnResize = (params) => {
        setEventColumnWidths(prevWidths => ({
            ...prevWidths,
            [params.colDef.field]: params.width,
        }));
    };

    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <PageHeader title="Manage Events"/>

                <Box sx={{
                    width: '100%',
                    height: '100%',
                    position: 'relative',
                }}>
                    <DataGrid
                        columns={columns}
                        rows={events}
                        paginationMode="server"
                        rowCount={totalRowCount}
                        pageSizeOptions={[10, 20, 50]}
                        paginationModel={paginationModel}
                        onPaginationModelChange={setPaginationModel}
                        slots={{toolbar: GridToolbar}}
                        disableRowSelectionOnClick={true}
                        onColumnWidthChange={handleColumnResize}
                        sx={{
                            height: '100%',
                            position: 'absolute',
                            width: '100%',
                            '& .MuiDataGrid-cell:focus': {
                                outline: 'none',
                            },
                            // fontSize: '1rem',
                        }}
                    />
                </Box>
            </Box>
        </>
    );
};

export default EventsTable;
