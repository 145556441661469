import {useEffect, useState} from "react";
import {getBusinessUnitCodes} from "../services/UserService";
import {useQuery} from "@tanstack/react-query";

export function useBusinessUnitCodes(enabled = true, resetKey, callback = () => {}) {

    const [businessUnitCodes, setBusinessUnitCodes] = useState([]);
    // use useQuery to fetch business unit data

    const {data} = useQuery({
        queryKey: ['businessUnitCodes', resetKey],
        queryFn: () => getBusinessUnitCodes(),
        retry: false,
        enabled: enabled,
    })

    useEffect(() => {
        if (data) {
            const newBusinessUnitCodes = data.map((code) => ({id: code, name: code}));
            setBusinessUnitCodes(newBusinessUnitCodes);
            callback(newBusinessUnitCodes);
        }
    }, [data]);

    // useEffect(() => {
    //     // if (businessUnitCodes.length > 0) {
    //         callback(businessUnitCodes);
    //     // }
    // }, [businessUnitCodes]);

    return businessUnitCodes;
}