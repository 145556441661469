import RepsTable from './screens/reps/RepsTable';
import Orders from './screens/Orders';
import SignIn from './screens/SignIn';
import repsIcon from './assets/images/reps-icon.svg';
import ordersIcon from './assets/images/orders-icon.svg';
import EventsTable from "./screens/events/EventsTable";

const routes = [
  {
    name: "Manage Reps",
    key: "reps",
    path: "/reps",
    icon: repsIcon,
    authRequired: true,
    component: <RepsTable />,
  },
  {
    name: "Manage Events",
    key: "events",
    icon: ordersIcon,
    path: "/events",
    component: <EventsTable />,
  },
  {
    name: "Sign In",
    key: "sign-in",
    path: "/sign-in",
    component: <SignIn />,
  },
];

export default routes;
