import {useEffect} from "react";
import {useLogout} from "./useLogout";
import {useLocalStorage} from "./useLocalStorage";

export function useCurrentUser(logoutIfNull = false, callback = () => {}) {

  const [currentUser, setCurrentUser] = useLocalStorage("MFE_CURRENT_USER", null);
  const logout = useLogout();

  useEffect(() => {
    if (!currentUser && logoutIfNull) {
      logout();
    }
  }, [currentUser, logoutIfNull]);

    useEffect(() => {
      if (currentUser) {
        callback(currentUser);
      }
    }, [currentUser]);

  const updateCurrentUser = (user) => {
    if (user.isSystemAdmin === undefined) {
      user.isSystemAdmin = user.authorities && user.authorities.length <= 2 && user.authorities.includes('ROLE_ADMIN');
    }
    setCurrentUser(user);
  }

  return [currentUser, updateCurrentUser];
}