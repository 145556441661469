import {Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select} from "@mui/material";
import {useBusinessUnitCodes} from "../hooks/useBusinessUnitCodes";
import {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {getBusinessUnitCodes} from "../services/UserService";
import {v4 as uuidV4} from 'uuid';

const MyCheckbox = ({label, checked, editable, onChange, ...props}) => {

    console.log('Rendering MyCheckbox: ' + label);

    return (
        <FormControl fullWidth>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={checked}
                        color={editable ? 'primary' : 'default'}
                        onChange={onChange}
                        disabled={!editable}
                        {...props}
                    />
                }
                label={label}/>
        </FormControl>
    );
}

export default MyCheckbox;